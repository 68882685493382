export default [
  {
    path: "#intro",
    text: "about",
  },
  {
    path: "#processes",
    text: "how it works",
  },
  // {
  //   path: "#prices",
  //   text: "prices",
  // },
  {
    path: "#contact",
    text: "signup",
  },
]
